var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('page-title-block',{attrs:{"page-name":"Consignments","store-name":_vm.storeName}}),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.states,"item-text":"text","item-value":"code","attach":"","chips":"","label":"Consignment States","multiple":"","clearable":""},model:{value:(_vm.searchStates),callback:function ($$v) {_vm.searchStates=$$v},expression:"searchStates"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.displayData,"headers":_vm.headers,"items-per-page":-1,"sort-by":"lastUpdateTime","show-select":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("longDate")(item.date)))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("statusConverter")(item.state)))])]}},{key:"item.lastUpdateTime",fn:function(ref){
var item = ref.item;
return [(item.lastUpdateTime)?_c('span',[_vm._v(_vm._s(_vm._f("longDate")(item.lastUpdateTime)))]):_c('span',[_vm._v("Never")])]}},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.showOrder(item.id)}}},[_vm._v(_vm._s(item.orderId))])]}}],null,true),model:{value:(_vm.selectedCATS),callback:function ($$v) {_vm.selectedCATS=$$v},expression:"selectedCATS"}})],1)],1),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('c-a-t-s-summary-view',{attrs:{"cat-item":_vm.summaryOrderDetails}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }