<template>
  <v-container>
    <page-title-block page-name="Consignments" :store-name="storeName"></page-title-block>
    <v-row>
      <v-col>
        <v-select
          v-model="searchStates"
          :items="states"
          item-text="text"
          item-value="code"
          attach
          chips
          label="Consignment States"
          multiple
          clearable
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :items="displayData"
          :headers="headers"
          :items-per-page="-1"
          sort-by="lastUpdateTime"
          show-select
          v-model="selectedCATS"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span>{{item.date | longDate}}</span>
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <span>{{item.state | statusConverter}}</span>
          </template>
          <template v-slot:[`item.lastUpdateTime`]="{ item }">
            <span v-if="item.lastUpdateTime">{{item.lastUpdateTime | longDate}}</span>
            <span v-else>Never</span>
          </template>
          <template v-slot:[`item.orderId`]="{ item }">
            <a @click="showOrder(item.id)">{{item.orderId}}</a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showDialog"
      width="600px"
    >
      <c-a-t-s-summary-view
        :cat-item="summaryOrderDetails"
      ></c-a-t-s-summary-view>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import statuses from '../../../resources/statusMapping.json'
import CATSSummaryView from '@/components/CATSSummaryView'
import PageTitleBlock from '@/components/PageTitleBlock'
export default {
  name: 'ConsignmentDashboard',
  components: { PageTitleBlock, CATSSummaryView },
  data () {
    return {
      loading: false,
      error: null,
      timer: '',
      showDialog: false,
      summaryOrderDetails: {},
      searchStates: [],
      headers: [
        {
          text: 'Order Id',
          value: 'orderId'
        },
        {
          text: 'Customer Name',
          value: 'customerName'
        },
        {
          text: 'Phone Number',
          value: 'number'
        },
        {
          text: 'State',
          value: 'state'
        },
        {
          text: 'Last Updated',
          value: 'lastUpdateTime'
        }
      ],
      selectedCATS: [],
      displayData: []
    }
  },
  filters: {
    menuSummariser: (text) => {
      return text ? text.split(' ').map(word => word.replace(/[aeiou]/ig, '')).map(word => word.slice(0, 3)).join(' ') : ''
    }
  },
  computed: {
    ...mapState('instore', ['stores', 'smsSentCATS', 'timeSetCATS']),
    storeId: function () {
      return this.$route.params.storeId
    },
    storeName: function () {
      const storeDetails = this.stores.find(item => item.storeId === this.storeId)
      return storeDetails ? storeDetails.storeName : 'Unknown'
    },
    states: function () {
      return Object.entries(statuses).map(entries => ({
        code: entries[1].db,
        text: entries[1].display
      }))
    }
  },
  created () {
    this.fetchData()
    this.timer = setInterval(this.fetchData, 60000)
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',
    searchStates: 'fetchData'
  },
  methods: {
    showSearchPopup: function () {
      this.showSearch = true
    },
    fetchData: async function () {
      this.error = null
      this.loading = true
      if (this.searchStates.length) {
        const token = await this.$auth.getTokenSilently()
        return this.$store.dispatch('instore/loadUncollectedData', { storeId: this.storeId, token, states: this.searchStates })
          .then((result) => {
            console.log(result)
            const data = []
            Object.entries(result).forEach(item => {
              item[1] = item[1].map(val => {
                val.state = item[0]
                return val
              })
              console.log('item is ', item[1])
              data.push(...item[1])
            })

            this.displayData = data
          })
          .then(() => (this.loading = false))
          .catch(err => (this.error = err))
      } else {
        this.loading = false
        this.displayData = []
      }
    },
    showOrder: function (id) {
      fetch(`${process.env.VUE_APP_API_URL}/cats/${id}`)
        .then(response => response.json())
        .then(data => {
          this.summaryOrderDetails = data
        })
        .then(() => (this.showDialog = true))
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>
